
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage.vue'

type Partner = 'sunrise' | 'salt' | 'wingo' | 'yallo' | 'talktalk' | 'mbudget' | 'quickline'

const partnerImagesMap: Record<Partner, any[]> = {
  yallo: [require('~/assets/images/provider-logos/v6/Yallo.webp'), require('~/assets/images/provider-logos/v6/Yallo@2x.webp')],
  sunrise: [require('~/assets/images/provider-logos/v6/Sunrise.webp'), require('~/assets/images/provider-logos/v6/Sunrise@2x.webp')],
  salt: [require('~/assets/images/provider-logos/v6/Salt.webp'), require('~/assets/images/provider-logos/v6/Salt@2x.webp')],
  wingo: [require('~/assets/images/provider-logos/v6/Wingo.webp'), require('~/assets/images/provider-logos/v6/Wingo@2x.webp')],
  mbudget: [require('~/assets/images/provider-logos/v6/MBudget.webp'), require('~/assets/images/provider-logos/v6/MBudget@2x.webp')],
  talktalk: [require('~/assets/images/provider-logos/v6/TalkTalk.webp'), require('~/assets/images/provider-logos/v6/TalkTalk@2x.webp')],
  quickline: [require('~/assets/images/provider-logos/v6/Quickline.webp'), require('~/assets/images/provider-logos/v6/Quickline@2x.webp')],
}

@Component({
  components: { ResponsiveImage },
})
export default class Partners extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  readonly withShadow!: boolean

  get partners () {
    return [
      {
        name: 'Yallo',
        slug: 'yallo',
        images: partnerImagesMap.yallo,
      },
      {
        name: 'Sunrise',
        slug: 'sunrise',
        images: partnerImagesMap.sunrise,
      },
      {
        name: 'Salt',
        slug: 'salt',
        images: partnerImagesMap.salt,
      },
      {
        name: 'Wingo',
        slug: 'wingo',
        images: partnerImagesMap.wingo,
      },
      {
        name: 'TalkTalk',
        slug: 'talktalk',
        images: partnerImagesMap.talktalk,
      },
      {
        name: 'M-Budget',
        slug: 'm-budget',
        images: partnerImagesMap.mbudget,
      },
      {
        name: 'Quickline',
        slug: 'quickline',
        images: partnerImagesMap.quickline,
      },
    ]
  }
}
