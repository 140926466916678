
import { Component } from 'nuxt-property-decorator'
import type { MetaInfo } from 'vue-meta'
import { OnMounted, WithHead } from '~/core/vue.types'
import TVAdBanner from '~/components/promos/TVAdBanner.vue'
import Partners from '~/components/sections/Partners.vue'
import Credibility from '~/components/sections/Credibility.vue'
import FeaturesBanner, { FeaturesBannerItem } from '~/components/sections/FeaturesBanner.vue'
import Media from '~/components/sections/Media.vue'
import ContactUsForm from '~/components/sections/forms/ContactUsForm.vue'
import { getSEOMeta } from '~/utils/seo/meta'
import BestDealGuarantee from '~/components/widgets/BestDealGuarantee.vue'
import SaveRouteMixin from '~/mixins/save-route.mixin'
import { teamMembersConfigHome } from '~/core/config/team-members-config'
import { FeatureFlags } from '~/core/config/feature-flags'
import NewMainBanner from '~/components/MainBanner/NewMainBanner.vue'
import BlackFridayMainBanner from '~/components/MainBanner/BlackFridayMainBanner.vue'

import PromoThemeMixin from '~/mixins/promo-theme.mixin'

const HowItWorksCards = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/how-it-works/Cards.vue') })
const HowItWorksCardsItem = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/how-it-works/CardsItem.vue') })
const RatingSection = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/rating/RatingSection.vue') })
const MembersList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/team/MembersList.vue') })
const MembersItem = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/team/MembersItem.vue') })
const FaqList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/faq/FaqList.vue') })
const FaqItem = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/faq/FaqItem.vue') })
const SpecialOffersList = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/special-offers/SpecialOffersList.vue') })
const ClientsFeedback = () => ({ component: import(/* webpackChunkName: "sections" */ '~/components/sections/clients-feedback/ClientsFeedback.vue') })

@Component({
  components: {
    NewMainBanner,
    BlackFridayMainBanner,
    TVAdBanner,
    Partners,
    SpecialOffersList,
    Credibility,
    HowItWorksCards,
    HowItWorksCardsItem,
    Media,
    RatingSection,
    FaqList,
    FaqItem,
    MembersList,
    MembersItem,
    ContactUsForm,
    BestDealGuarantee,
    FeaturesBanner,
    ClientsFeedback,
  },
  mixins: [SaveRouteMixin],
})
export default class IndexPage extends PromoThemeMixin implements WithHead, OnMounted {
  private featuresBannerItems: FeaturesBannerItem[] = [
    {
      icon: require('~/assets/images/icons/features-banner/target.svg'),
      title: this.$t('credibilityCards.1.title') as string,
      description: this.$t('credibilityCards.1.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/calculator.svg'),
      title: this.$t('credibilityCards.2.title') as string,
      description: this.$t('credibilityCards.2.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/timer.svg'),
      title: this.$t('credibilityCards.3.title') as string,
      description: this.$t('credibilityCards.3.description') as string,
    },
    {
      icon: require('~/assets/images/icons/features-banner/flag-banner.svg'),
      title: this.$t('credibilityCards.4.title') as string,
      description: this.$t('credibilityCards.4.description') as string,
    },
  ]

  private featureBannersStyles = {
    '--features-icon-size': '46px',
    '--features-padding-y': '2.25rem',
  }

  private teamMembers = teamMembersConfigHome

  private featureBlackFriday = this.$featureFlags.isEnabled(FeatureFlags.BLACK_FRIDAY_PROMO)

  private featureNewVoucherProcess = this.$featureFlags.isEnabled(FeatureFlags.NEW_VOUCHER_PROCESS)

  showModal = true

  head (): MetaInfo {
    return getSEOMeta({
      url: this.$config.BASE_URL.concat(this.$route.path),
      title: this.$t('head.meta.index.title').toString(),
      description: this.$t('head.meta.index.description').toString(),
      langCode: this.$i18n.locale.toString(),
      imageUrl: this.$config.BASE_URL.concat('/og-share.jpg'),
      imageWidth: '1200',
      imageHeight: '630',
    })
  }

  mounted () {
    this.$analytics.newGTM.createPageViewEvent({
      routeName: this.$route.name as string,
      pageTitle: this.$metaInfo.title || '',
      fullPath: this.$route.fullPath,
      fromPath: this.$nuxt.context.from ? this.$nuxt.context.from.fullPath : '',
    }).send()
  }
}
