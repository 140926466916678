import { TeamMember } from '~/core/team-members.types'

const teamMembers: { [key: string]: TeamMember } = {
  ceo: {
    name: 'Walter Salchli',
    position: 'home.about.team.ceo.position',
    description: 'aboutUs.managers.ceo.description',
    descriptionShort: 'home.about.team.ceo.description',
    image: require('~/assets/images/team/walter-salchli.jpg'),
  },
  cfo: {
    name: 'Karim Mohr',
    position: 'home.about.team.cfo.position',
    description: 'aboutUs.managers.cfo.description',
    descriptionShort: 'home.about.team.cfo.description',
    image: require('~/assets/images/team/karim.jpg'),
  },
  cto: {
    name: 'Alexander Degtiarev',
    position: 'home.about.team.cto.position',
    description: 'aboutUs.managers.cto.description',
    descriptionShort: 'home.about.team.cto.description',
    image: require('~/assets/images/team/alexander-degtiarev.jpg'),
  },
  sco: {
    name: 'Nadine Rancetti',
    position: 'home.about.team.sco.position',
    description: 'aboutUs.managers.sco.description',
    descriptionShort: 'home.about.team.sco.description',
    image: require('~/assets/images/team/nadine.png'),
  },
  wsm: {
    name: 'Melanie Huber',
    position: 'home.about.team.wsm.position',
    description: 'aboutUs.managers.wsm.description',
    descriptionShort: 'home.about.team.wsm.description',
    image: require('~/assets/images/team/melanie.png'),
  },
  designer: {
    name: 'Andrey Demchuk',
    position: 'home.about.team.designer.position',
    description: 'aboutUs.managers.designer.description',
    descriptionShort: 'home.about.team.designer.description',
    image: require('~/assets/images/team/andrey.png'),
  },
}

export const teamMembersConfigHome: TeamMember[] = Object.values(teamMembers)

export const teamMembersConfigAboutUs: TeamMember[] = [
  teamMembers.ceo,
  teamMembers.cfo,
  teamMembers.cto,
  teamMembers.sco,
  teamMembers.wsm,
]
