
import { Component, Vue } from 'nuxt-property-decorator'
import { $api } from '~/plugins/axios'
import ContactUsFormModal from '~/components/modals/ContactUsFormModal.vue'

@Component({
  components: {
    ContactUsFormModal,
  },
})
export default class ContactUsForm extends Vue {
  private name = ''
  private email = ''
  private message = ''
  private modalView = false
  private formIsSuccess = false

  get modalContent () {
    if (this.formIsSuccess) {
      return {
        text: this.$t('home.contact.form.success.text'),
        subtext: this.$t('home.contact.form.success.subtext'),
      }
    }

    return {
      text: this.$t('home.contact.form.fail.text'),
      subtext: this.$t('home.contact.form.fail.subtext'),
    }
  }

  async mounted () {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.error(e)
    }
  }

  beforeDestroy () {
    this.$recaptcha.destroy()
  }

  async onSubmit () {
    if (!await this.$validator.validate()) {
      return
    }
    let token

    try {
      token = await this.$recaptcha.execute('contact')
    } catch (error) {
      throw new Error('captcha contact error:' + error)
    }

    try {
      await $api.CommonService.submitContactUsForm({
        name: this.name,
        email: this.email,
        message: this.message,
      }, token)

      this.formIsSuccess = true
      this.clearFields()
      this.$validator.reset()
    } catch (e) {
      this.formIsSuccess = false
    } finally {
      this.modalView = true
    }
  }

  clearFields () {
    this.name = ''
    this.email = ''
    this.message = ''
  }
}
