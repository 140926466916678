
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Credibility extends Vue {
  @Prop({ required: false, default: 'common' }) readonly type!: string

  get wrapperClassName () {
    return `credibility__cards--${this.type}`
  }
}
