
import { Component, Prop, PropSync, Vue } from 'nuxt-property-decorator'
import AppModal from '~/components/AppModal/AppModal.vue'

@Component({
  components: {
    AppModal,
  },
})
export default class ContactUsFormModal extends Vue {
  @PropSync('show', { type: Boolean, default: false, required: true })
  isShow!: boolean

  @Prop({ type: String, required: true })
  readonly text!: string

  @Prop({ type: String, required: true })
  readonly subtext!: string
}
