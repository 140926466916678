var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"black-friday-header"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"black-friday-header__content"},[_c('div',{staticClass:"black-friday-header__main"},[_c('div',{staticClass:"black-friday-header__badge"},[_c('ResponsiveImage',{attrs:{"srcset":[
              require('~/assets/images/promos/black-friday/bf-badge.webp'),
              require('~/assets/images/promos/black-friday/bf-badge@2x.webp'),
            ]}})],1),_vm._v(" "),_c('h1',{staticClass:"black-friday-header__title",attrs:{"data-e2e":"BlackFridayTitle"},domProps:{"innerHTML":_vm._s(_vm.$t(_vm.promoThemeOptions.pages.home.mainBanner.title.text))}}),_vm._v(" "),_c('SavingsCounter',{staticClass:"black-friday-header__counter",attrs:{"orders":_vm.totalOrders,"savings":_vm.totalSavings,"one-counter-mode":_vm.promoThemeOptions.pages.home.mainBanner.counter.oneCounterMode,"dark-mode":_vm.promoThemeOptions.darkMode}}),_vm._v(" "),_c('AlaoButton',{staticClass:"black-friday-header__cta text--center",attrs:{"href":_vm.localePath('quiz'),"size":"lg","data-e2e":"jumbotronFindPlanBtn"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.onCTAClick()}}},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.promoThemeOptions.pages.home.mainBanner.cta.text))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"black-friday-header__image"},[_c('ResponsiveImage',{attrs:{"srcset":[
            require('~/assets/images/promos/black-friday/main-banner-hero.webp'),
            require('~/assets/images/promos/black-friday/main-banner-hero@2x.webp'),
          ]}}),_vm._v(" "),_c('div',{staticClass:"discount-percent"},[_vm._v("\n          -81%\n        ")])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }