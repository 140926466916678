import { PromoTheme } from '~/core/config/promo-theme/types'
import { FeatureFlags } from '~/core/config/feature-flags'

const theme: PromoTheme = {
  featureFlag: FeatureFlags.BLACK_FRIDAY_PROMO,
  options: {
    darkMode: true,
    pages: {
      home: {
        mainBanner: {
          images: [
            require('~/assets/images/promos/black-friday/main-banner-hero.webp'),
            require('~/assets/images/promos/black-friday/main-banner-hero@2x.webp'),
          ],
          title: {
            text: 'home.blackFridayJumbotron.title',
          },
          counter: {
            oneCounterMode: true,
          },
          cta: {
            text: 'home.findYourPlan',
          },
        },
      },
      comparator: {
        featuresBanner: {
          images: [
            require('~/assets/images/promos/black-friday/alao-black-friday-benefits.webp'),
            require('~/assets/images/promos/black-friday/alao-black-friday-benefits@2x.webp'),
          ],
        },
      },
      osp: {
        topBanner: {
          images: [
            require('~/assets/images/promos/black-friday/offer-page-hero.webp'),
            require('~/assets/images/promos/black-friday/offer-page-hero@2x.webp'),
          ],
          badge: {
            type: 'text',
            text: 'promo.blackFriday.badge',
          },
        },
      },
    },
  },
}

export default theme
