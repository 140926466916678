
import { Component, namespace } from 'nuxt-property-decorator'

import ResponsiveImage from '~/components/ResponsiveImage/ResponsiveImage.vue'
import SavingsCounter from '~/components/SavingsCounter/SavingsCounter.vue'

import { CommonState, namespace as commonStore } from '~/store/modules/Common'
import PromoThemeMixin from '~/mixins/promo-theme.mixin'

const CommonStore = namespace(commonStore)

@Component({
  components: {
    ResponsiveImage,
    SavingsCounter,
  },
})
export default class BlackFridayMainBanner extends PromoThemeMixin {
  @CommonStore.State('totalOrders')
  readonly totalOrders!: CommonState['totalOrders']

  @CommonStore.State('totalSavings')
  readonly totalSavings!: CommonState['totalSavings']

  onCTAClick () {
    const btnText = this.$t('home.newJumbotron.startSaving') as string

    this.$analytics.gtm.sendQuizCTAClickEvent(this.$i18n.locale, btnText)

    this.$router.push(this.localePath('quiz'))
  }
}
